import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { summarizer } from 'utils/functions';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Input from 'components/Input';

const ImportModal = ({ cadastro_id, especie_id, itens = [], onSubmit }) => {
  const defaultValues = { search: '' };
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const { control, handleSubmit } = useForm({ defaultValues });
  const { getLoading, getDocumentoImportacao, documentoImport } =
    useContext(DocumentosContext);

  useEffect(() => {
    setSelected([]);
    getDocumentoImportacao({ cadastro_id, especie_id });
  }, []);

  useEffect(() => {
    setRows(
      documentoImport
        ?.map((d) => {
          const gerados = d?.Destinos?.filter(
            (d) =>
              (d?.especie_id === 53 && !Boolean(d?.hasDestino)) ||
              (d?.especie_natureza === 'FATURA' && d?.especie_grupo === 'SAIDA')
          );
          const quantidade =
            (d?.quantidade || 0) - (summarizer(gerados, 'quantidade') || 0);
          let preco = d?.preco;
          const cotacao = d?.Documento?.DocumentoMoeda?.cotacao;
          const cotacao_atual = d?.Documento?.DocumentoMoeda?.cotacao_atual;
          if (Boolean(cotacao) && Boolean(cotacao_atual)) {
            preco = Number(
              (Number((preco / cotacao).toFixed(2)) * cotacao_atual).toFixed(2)
            );
          }
          const subtotal =
            (preco || 0) * (quantidade || 0) + (d?.desconto_vlr || 0);
          return {
            ...d,
            documento: d?.Documento?.documento,
            quantidade,
            quantidade_max: quantidade,
            preco,
            preco_max: preco,
            um: d?.UnidadeMedida?.sigla,
            subtotal,
          };
        })
        ?.filter(
          (f) =>
            Boolean(f?.quantidade) &&
            !itens?.some((s) =>
              s?.Origens?.some((ss) => ss?.documento_item_org_id === f?.id)
            )
        )
    );
  }, [documentoImport]);

  const onSearch = (values) => {
    setSelected([]);
    const newRows = rows?.filter((f) =>
      Object.keys(f).some(
        (key) =>
          (f[key] || '')
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(values?.search?.toLowerCase()) !== -1
      )
    );
    setRows(newRows);
  };

  const onConfirm = () => {
    const newItens = selected?.map((s) => {
      const item = rows?.find((f) => f?.id === s);
      const Estruturas = item?.DocumentoItemEstruturas?.map((e) => {
        delete e?.Produto;
        delete e?.documento_item_id;
        delete e?.id;
        return e;
      });
      const Processos = item?.DocumentoItemProcessos?.map((p) => {
        delete p?.DocumentoItemProcessoAponts;
        delete p?.documento_item_id;
        delete p?.id;
        return p;
      });
      const Aprovacoes = item?.DocumentoItemAprovacaos?.map((a) => {
        delete a?.documento_item_id;
        delete a?.id;
        return a;
      });
      if (Boolean(Estruturas?.length)) item['Estruturas'] = Estruturas;
      if (Boolean(Processos?.length)) item['Processos'] = Processos;
      if (Boolean(Aprovacoes?.length)) item['Aprovacoes'] = Aprovacoes;
      item['Origens'] = [{ documento_item_org_id: item?.id }];
      item['despac'] = parseFloat((item?.despac * item?.quantidade).toFixed(2));
      item['eventuais'] = parseFloat(
        (item?.eventuais * item?.quantidade).toFixed(2)
      );
      item['frete'] = parseFloat((item?.frete * item?.quantidade).toFixed(2));
      item['outros'] = parseFloat((item?.outros * item?.quantidade).toFixed(2));
      item['seguro'] = parseFloat((item?.seguro * item?.quantidade).toFixed(2));
      item['_doc'] = item?.documento_id;
      delete item?.id;
      delete item?.um;
      delete item?.cfo_id;
      delete item?.calcimp;
      delete item?.documento;
      delete item?.documento_id;
      delete item?.Produto;
      delete item?.Destinos;
      delete item?.Documento;
      delete item?.UnidadeMedida;
      delete item?.DocumentoItemProcessos;
      delete item?.DocumentoItemEstruturas;
      delete item?.DocumentoItemAprovacaos;
      return item;
    });
    onSubmit([...itens, ...newItens]);
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          name="search"
          size="small"
          placeholder="Buscar..."
          control={control}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e?.key === 'Enter') {
              handleSubmit(onSearch)();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'descricao',
              headerName: 'Descrição',
              flex: 4,
              sortable: false,
            },
            {
              field: 'documento',
              headerName: 'Nº do Documento',
              flex: 1,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value, row }) => value + ' ' + row?.um,
            },
            {
              field: 'desconto_vlr',
              headerName: 'Desconto',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                (value || 0)?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'preco',
              headerName: 'Preço',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                (value || 0)?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'subtotal',
              headerName: 'Subtotal',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                (value || 0)?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          checkboxSelection
          keepNonExistentRowsSelected
          selectionModel={selected}
          onSelectionModelChange={(ids) => setSelected(ids)}
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button variant="contained" color="primary" onClick={onConfirm}>
          IMPORTAR
        </Button>
      </Grid>
    </Grid>
  );
};
export default ImportModal;

const styles = {
  card: {
    marginTop: '1em',
  },
  semiCard: {
    width: '100%',
  },
  button: {
    margin: '1em 0',
  },
  gridContainer: {
    height: '40vh',
  },
  grid: {
    '& .MuiDataGrid-virtualScroller': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#888 #f0f0f0',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: '#f0f0f0',
      borderRadius: '4px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
};

export default styles;

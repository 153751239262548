import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Button from 'components/Button';
import moment from 'moment';

const CarrinhoModal = ({ itens = [], setItens, onSubmit }) => {
  const [itensMod, setItensMod] = useState(itens);

  useEffect(() => {
    setItens(itensMod);
  }, [itensMod]);

  const Line = ({ value, label }) => (
    <Box display="flex" alignItems="center" textAlign="start">
      <Typography flex={1} variant="subtitle2">
        {label}:
      </Typography>
      <Typography flex={2} variant="body2">
        {value || '-'}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataGrid
          rows={itensMod}
          columns={[
            {
              field: 'descricao',
              headerName: 'Produto',
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => (
                <Box display="flex" flex={1} flexDirection="column">
                  <Typography variant="body1" fontWeight="bold" mb={1}>
                    {row?.descricao}
                  </Typography>
                  <Line label="Referência" value={row?.referencia} />
                  <Line label="Código de Barras" value={row?.cdbarra} />
                </Box>
              ),
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              width: 150,
              sortable: false,
              renderCell: ({ row }) => (
                <Typography variant="body2">
                  {row?.quantidade || 0} {row?.unidade_medida || ''}
                </Typography>
              ),
            },
            {
              field: 'documento',
              headerName: 'Documento',
              width: 300,
              sortable: false,
              renderCell: ({ row }) => (
                <Box>
                  {Boolean(row?.documento) && (
                    <Typography variant="body1" fontWeight="bold" mb={1}>
                      Nº {row?.documento}
                    </Typography>
                  )}
                  {row?.dtemissao && moment(row?.dtemissao)?.isValid() && (
                    <Typography variant="caption">
                      Emitido em{' '}
                      {moment(row?.dtemissao)?.format('DD/MM/YYYY [às] HH:mm')}
                    </Typography>
                  )}
                </Box>
              ),
            },
            {
              field: 'kit_id',
              headerName: 'Código do Kit',
              type: 'number',
              width: 150,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              width: 100,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() =>
                    setItensMod((prev) => prev.filter((f) => f.id !== row.id))
                  }
                />,
              ],
            },
            ,
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          getRowHeight={() => 'auto'}
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Vender
        </Button>
      </Grid>
    </Grid>
  );
};

export default CarrinhoModal;

import React, { useContext, useState } from 'react';
import { Badge, Box, Fab } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { encryptURL } from 'utils/functions';
import { VendaExternaContext } from 'contexts/VendaExternaContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import CarrinhoModal from './Modals/CarrinhoModal';
import Filter from './filter';
import mock from './mock';

const VendaExternaItens = () => {
  const rotina = 'RemessasVendaExternaItens';
  const titulo = 'Itens de Vendas Externas (Fora do Estabelecimento)';
  const navigate = useNavigate();
  const [itens, setItens] = useState([]);
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { vendaExternaItens, getVendaExternaItens, getLoading } =
    useContext(VendaExternaContext);
  const { control, getValues, reset } = useForm({
    defaultValues: vendaExternaItens?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getVendaExternaItens({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: vendaExternaItens?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: vendaExternaItens?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={vendaExternaItens?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    // {
    //   name: 'Consignar',
    //   icon: 'change_circle',
    //   action: (selected) => {
    //     const itens = selected?.map((s) => ({
    //       ...s,
    //       quantidade_max: s?.quantidade,
    //       preco_max: s?.preco,
    //       subtotal:
    //         (s?.quantidade || 0) * (s?.preco || 0) + (s?.desconto_vlr || 0),
    //     }));
    //     navigate(`/app/Remessas/Consignar`, {
    //       state: { itens, vendedor_id: user?.entidade_id },
    //     });
    //   },
    // },
    // {
    //   name: 'Vender',
    //   icon: 'monetization_on',
    //   action: (selected) => {
    //     const itens = selected?.map((s) => s?.id);
    //     const hash = encryptURL({ itens });
    //     navigate(`/app/Remessas/Venda/${hash}`);
    //   },
    // },
  ];

  const rowOptions = [
    {
      name: 'Resumo da Remessa',
      icon: 'summarize',
      menu: true,
      action: ({ row }) =>
        navigate(`/app/Remessas/Resumo/${row?.documento_id}`),
    },
    {
      name: 'Vender',
      icon: 'shopping_cart',
      show: ({ row }) => !itens?.some((s) => s?.id === row?.id),
      action: ({ row }) => setItens((prev) => [...prev, row]),
    },
  ];

  return (
    <Container>
      <Header
        titulo={titulo}
        rotina={rotina}
        colunas={vendaExternaItens?.colunas}
      />
      <Card>
        <Grid
          grid={vendaExternaItens}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={vendaExternaItens} control={control} drops={drops} />
          }
        />
      </Card>
      <Box sx={{ position: 'fixed', bottom: 20, right: 40 }}>
        <Badge
          badgeContent={itens?.length}
          color="secondary"
          max={99}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Fab
            color="primary"
            onClick={() =>
              openDialog(
                <CarrinhoModal
                  itens={itens}
                  setItens={setItens}
                  onSubmit={() => {
                    const hash = encryptURL({
                      itens: itens?.map((s) => s?.id),
                    });
                    closeDialog();
                    navigate(`/app/Remessas/Venda/${hash}`);
                  }}
                />,
                'Itens adicionados ao carrinho'
              )
            }
            sx={{ zIndex: 1 }}
          >
            <ShoppingCart />
          </Fab>
        </Badge>
      </Box>
    </Container>
  );
};

export default VendaExternaItens;

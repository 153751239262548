import React, { useContext, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropTipoContrato, dropReajusteContrato } from 'utils/drops';
import { findOnArray, summarizer, getDatePagto } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import api from 'services/api';
import localeText from 'utils/localeText';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const gridRef = useRef(null);
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    dtinicont: moment().format('YYYY-MM-DD HH:mm:ss'),
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    documento: '',
    tipo: null,
    reajuste: null,
    intervalo: '',
    filial_id: user?.filial_id,
    cadastro_id: null,
    vendedor_id: null,
    entidade_endereco_id: null,
    observacao: '',
    dadosadc: '',
    //ITENS
    descricao: '',
    quantidade: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    infadc: '',
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
  };
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });
  const especie_id = pathname?.includes('/Contrato')
    ? 15
    : pathname?.includes('/Orcamento')
    ? 24
    : null;

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      ...values?.itens,
      {
        subtotal,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        planoconta_id: values?.planoconta_id,
        ccusto_id: values?.ccusto_id,
        aplicacao_id: values?.aplicacao_id,
        frota_id: values?.frota_id,
        infadc: values?.infadc,
      },
    ]);
    resetField('descricao');
    resetField('quantidade');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('planoconta_id');
    resetField('ccusto_id');
    resetField('aplicacao_id');
    resetField('frota_id');
    resetField('infadc');
    handleSubmit(onRecalc)();

    if (gridRef?.current) {
      const virtualScroller = gridRef?.current?.querySelector(
        '.MuiDataGrid-virtualScroller'
      );
      if (virtualScroller) {
        setTimeout(() => {
          virtualScroller?.scrollTo({
            top: virtualScroller?.scrollHeight,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    handleSubmit(onRecalc)();
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
    handleSubmit(onRecalc)();
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const condicoes =
        findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      const valor_total = summarizer(values?.itens, 'subtotal');

      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: 'CLIENTE',
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.vendedor_id) {
        setValue('vendedor_id', data?.vendedor_id);
      }
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
    }
    setValue('entidade_endereco_id', null);
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id,
        natureza_operacao_id: 51,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Saida: {
        condicao_pagamento_id: values?.condicao_pagamento_id,
        vendedor_id: values?.vendedor_id,
        entidade_endereco_id: values?.entidade_endereco_id,
        dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Itens: values?.itens?.map((i, index) => ({
        ...i,
        sequencia: index + 1,
      })),
      Financeiros: values?.pagamento,
      Contrato: {
        tipo: values?.tipo,
        reajuste: values?.reajuste,
        intervalo: values?.intervalo,
        dtinicont: values?.dtinicont,
      },
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header
        titulo={
          'Gerar ' +
          (especie_id === 15
            ? 'Contrato'
            : especie_id === 24
            ? 'Orçamento'
            : '')
        }
      />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtinicont"
              control={control}
              label="Data de Início do Contrato"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="documento" control={control} label="Nº do Documento" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo do Contrato"
              options={dropTipoContrato}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="reajuste"
              control={control}
              label="Reajuste"
              options={dropReajusteContrato}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="intervalo"
              control={control}
              label="Intervalo (Meses)"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={onChangeEntidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="vendedor_id"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="entidade_endereco_id"
              control={control}
              label="Local de Fornecimento"
              options={drops?.EntidadeEndereco?.filter(
                (e) => e?.entidade_id === watch('cadastro_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="preco"
                    control={control}
                    label="Preço"
                    disabled={user?.permissoes?.block_altera_precos}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="planoconta_id"
                    control={control}
                    label="Plano de Conta"
                    options={drops?.Planoconta?.filter(
                      (p) => p?.operacional === 'SIM'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="ccusto_id"
                    control={control}
                    label="Centro de Custo"
                    options={drops?.Ccusto}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="aplicacao_id"
                    control={control}
                    label="Aplicação"
                    options={drops?.Aplicacao}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="frota_id"
                    control={control}
                    label="Frota"
                    options={drops?.Frota}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={styles?.gridContainer} ref={gridRef}>
                    <DataGrid
                      sx={styles?.grid}
                      rows={(watch('itens') || [])?.map((m, index) => ({
                        ...m,
                        index,
                      }))}
                      columns={[
                        {
                          field: 'descricao',
                          headerName: 'Descrição',
                          sortable: false,
                          width: 400,
                        },
                        {
                          field: 'quantidade',
                          headerName: 'Quantidade',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueGetter: ({ row }) => {
                            const um = findOnArray(
                              row?.unidade_medida_id,
                              drops?.UnidadeMedida,
                              'label'
                            );
                            return `${row?.quantidade || 0} ${um || ''}`;
                          },
                        },
                        {
                          field: 'desconto_vlr',
                          headerName: 'Desconto',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'preco',
                          headerName: 'Preço Unitário',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'subtotal',
                          headerName: 'Sub Total',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'actions',
                          headerName: 'Ações',
                          type: 'actions',
                          width: 100,
                          getActions: ({ row }) => [
                            <GridActionsCellItem
                              size="small"
                              icon={<Edit />}
                              label="Editar"
                              onClick={() =>
                                openDialog(
                                  <ItemModal
                                    item={row}
                                    onSubmit={(values) =>
                                      onEditItem({
                                        values,
                                        index: row?.index,
                                      })
                                    }
                                  />,
                                  'Editar Item'
                                )
                              }
                            />,
                            <GridActionsCellItem
                              size="small"
                              icon={<Delete />}
                              label="Excluir"
                              onClick={() => onDeleteItem(row?.index)}
                            />,
                          ],
                        },
                      ]}
                      getRowHeight={() => 'auto'}
                      getRowId={(row) => JSON?.stringify(row)}
                      hideFooter
                      density="compact"
                      disableSelectionOnClick
                      disableColumnMenu
                      showCellRightBorder
                      showColumnRightBorder
                      localeText={localeText}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={findOnArray(
                      watch('forma_pagto_id'),
                      drops?.FormaDePagamento,
                      'condicoes'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
